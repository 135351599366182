import React from 'react'

export default ({color}) => (

<svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 width="512px" viewBox="0 0 512 512" style={{enableBackground: "new 0 0 512 512"}} xmlSpace="preserve">
<g>
	<path fill={color} d="M256,0C114.615,0,0,114.615,0,256s114.615,256,256,256s256-114.615,256-256S397.385,0,256,0z M403.121,180.544
		c0.146,3.254,0.221,6.528,0.221,9.819c0,100.311-76.354,215.982-215.979,215.982c-42.868,0-82.768-12.568-116.363-34.106
		c5.938,0.702,11.981,1.062,18.108,1.062c35.565,0,68.295-12.138,94.275-32.496c-33.217-0.611-61.25-22.562-70.912-52.717
		c4.637,0.885,9.392,1.361,14.283,1.361c6.923,0,13.629-0.928,19.999-2.662c-34.726-6.977-60.893-37.656-60.893-74.434
		c0-0.32,0-0.639,0.008-0.955c10.235,5.685,21.939,9.1,34.382,9.494c-20.37-13.611-33.77-36.846-33.77-63.184
		c0-13.911,3.744-26.952,10.278-38.162c37.439,45.927,93.374,76.146,156.462,79.314c-1.294-5.559-1.965-11.351-1.965-17.302
		c0-41.92,33.99-75.906,75.909-75.906c21.832,0,41.562,9.218,55.408,23.97c17.291-3.404,33.537-9.721,48.207-18.42
		c-5.668,17.727-17.705,32.603-33.377,41.997c15.355-1.834,29.984-5.916,43.598-11.952
		C430.824,156.471,417.955,169.84,403.121,180.544z"/>
</g>

</svg>
)